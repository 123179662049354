<template>
  <div class="tw-py-4 bg-credits">
      <text-secondary class="tw-text-sm">© 2021 — MicroCenter</text-secondary>
    </div>
</template>

<script>
import TextSecondary from '@/components/atoms/TextSecondary'

export default {
  components: {
    TextSecondary,
  },
  props: ['title'],

}
</script>

<style lang="sass">
  .bg-credits
    background: #F5F5F5
</style>